.metric-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-bottom: 20px;
}
.graph-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
}

@media only screen and (min-width: 600px) {
    .metric-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
        margin-bottom: 16px;
    }
    .graph-grid {
        grid-row-gap: 16px;
        grid-column-gap: 16px;
    }
}

@media only screen and (min-width: 900px) {
    .metric-grid {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
        margin-bottom: 24px;
    }
    .graph-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 24px;
    }
}


@media only screen and (min-width: 1200px) {
    .metric-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .graph-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 1600px) {
    .metric-grid {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 32px;
        margin-bottom: 32px;
    }
    .graph-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 32px;
        grid-column-gap: 56px;
    }
}
