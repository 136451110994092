@import url("https://use.typekit.net/uol4ibh.css");

@font-face {
  font-family: Hyperwave;
  src: local("Hyperwave"), url(./fonts/Hyperwave.otf) format("opentype");
}

@font-face {
  font-family: BiosBold;
  src: local("BiosBold"), url(./fonts/BiosBold.otf) format("opentype");
}

@font-face {
  font-family: BiosRegular;
  src: local("BiosRegular"), url(./fonts/BiosRegular.otf) format("opentype");
}

:root {
  --bright-pink: #FF00E5;
  --black: #000000;
  --white: #FFFFFF;
  --white-85: #FFFFFF85;
  --charmz-purple: #9990FF;
  --fuel-rod-green: #45F08C;
  --data-gradient: linear-gradient(#FF00E570, #9900FF70);
  --pink-gradient: #661046;
  --red: #FF154D;
  --dark-green: #004D35;

  --page-margins: 20px;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}

h1 {
  font-family: Hyperwave, sans-serif;
  font-size: 40px;
  line-height: 42px;
  color: var(--white);
}

h2 {
  font-family: BiosBold, sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: var(--white);
}

h5 {
  font-family: BiosRegular, sans-serif;
  font-size: 12px;
  color: var(--white);
}

h6 {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: var(--white-85);
}

p {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
}

tspan {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
}

.recharts-cartesian-axis-tick-value {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  fill: var(--white);
}

.recharts-xAxis .recharts-cartesian-axis-tick-value {
  fill: var(--white);
}

.recharts-surface {
  padding-right: 5px;
  overflow-x: overlay;
}

.treemap .recharts-wrapper .recharts-surface {
  overflow-x: hidden;
  padding-left: 5px;
}

g.recharts-cartesian-grid-horizontal line {
  stroke: #FFFFFF35;
}

span.recharts-legend-item-text {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
}

.recharts-scatter .recharts-scatter-symbol .recharts-symbols:hover {
  fill: white;
}

.page-divider {
  margin: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #FF00E500 0%, #FF00E5 50%, #FF00E500 100%);
}

.page-margins {
  padding-left: var(--page-margins);
  padding-right: var(--page-margins);
}

.page-margins.top {
  padding-top: 30px;
}

@media screen and (min-width: 600px) {
  :root {
    --page-margins: 50px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --page-margins: 100px;
  }
}

@media screen and (min-width: 1600px) {
  :root {
    --page-margins: 150px;
  }
}

.recharts-area path,
.recharts-bar-rectangle path {
  fill-opacity: 0.8;
}