#navbar {
    background-color: var(--black);
    height: 75px;
    margin: 0;
}

nav {
    height: 100%;
    display: inline-flex;
    flex-direction: row;
    overflow: hidden;
}

nav .banner-button svg {
    height: 42px;
    width: auto;
    margin-top: 4px;
    align-self: center;
    fill: var(--white);
}

nav .banner-button {
    margin-right: 30px;
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

nav button svg {
    transition: fill .25s ease;
}

nav button:hover svg {
    fill: var(--bright-pink);
}

@keyframes color-transition {
    0% {
        fill: var(--white);
    }
    100% {
        fill: var(--bright-pink);
    }
}

nav > * {
    display: flex;
    height: 100%;
    white-space: nowrap;
    align-items: center;
}

.nav-tab {
    margin-left: 25px;
    text-decoration: none;
    color: var(--white);
    transition: color .25s ease;
}

.nav-tab > h2 {
    color: inherit;
}

.nav-tab.active {
    color: #FF00E5D9;
}

.nav-tab:hover,
.nav-tab:active {
    color: var(--bright-pink);
    opacity: 1;
}

.nav-mobile-tabs {
    display: none;
}

/* for screen sizes less than 600px, hide the nav tabs */
@media screen and (max-width: 800px) {
    .nav-tabs {
        display: none;
    }
    .nav-mobile-tabs {
        display: flex;
    }
    #nav-burger {
        margin-left: auto;
    }
    nav {
        justify-content: space-between;
        width: 100%;
    }
}