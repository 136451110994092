#footer {
    position: absolute;
    /* margin-top: 20px; */
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 15px;
}

.footer-items {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding-inline: 15%;
    text-align: center;
}

.footer-link {
    text-decoration: none;
    color: var(--white);
}

.footer-link > h5 {
    color: inherit;
    font-family: BiosBold, sans-serif;
}

.footer-link.repo {
    margin-top: 5px;
}

.footer-link.repo > svg {
    width: 25px;
    height: 25px;
}

.footer-link:hover,
.footer-link:active,
.footer-link:focus {
    color: var(--bright-pink);
}

.created-by {
    display: inline-flex;
}

.footer-break {
    display: inline-flex;
}

@media only screen and (max-width: 600px) {
    .created-by-block {
        display: block;
    }
    .footer-items {
        padding-inline: 5%;
    }
}
