.page-bg {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    z-index: 900;
    position: relative;
    /* background: radial-gradient(ellipse at bottom, #FF00E590 0%, var(--black) 85%); */
    background: radial-gradient(ellipse at bottom, #9900FF90 0%, var(--black) 90%);
    padding-bottom: 60px;
}

.section-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: center;
}

.section-header > h2 {
    font-size: 24px;
}

.section-header > img {
    height: 32px;
    margin-right: 10px;
    margin-top: 2px;
}